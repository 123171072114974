<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link
      href="/dashboard"
      class="brand-logo"
    >
      <b-img
        :src="require('@/assets/images/logo/logo_azul_Plenitas.png')"
        fluid
        style="height: 60px"
        alt="Logo"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('maintenance') }} 🛠
        </h2>

        <p class="mb-3">
          {{ $t('sorry') }}
        </p>

        <!-- img -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Under maintenance page"
        />
      </div>
    </div>
  </div>
  <!-- / Under maintenance-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BLink,

    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
    const baseURL = process.env.VUE_APP_BACK_URL
    const graphqlQuery = {
      query: `
    {
      allClients {
        edges {
          node {
            name
          }
        }
      }
    }
  `,
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(graphqlQuery),
    }
    fetch(baseURL, requestOptions)
      .then(response => {
        if (response.status === 503) {
          // eslint-disable-next-line
          console.log('Respuesta 503, en mantenimiento')
        } else {
          this.$router.push('/dashboard')
        }
      })
      .catch(error => {
        // this.$router.push('/dashboard')
        // eslint-disable-next-line
        console.error('Error de solicitud:', error)
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
